import React, { useEffect, useMemo, useRef, useState } from 'react'
import MenuActions from '../MenuActions'
import Player from './Player'
import PotMoney from './PotMoney'
import { useSelector } from 'react-redux'
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import coins from '../../../../../assets/img/games/coins_transparent.png';

import hotToast from 'react-hot-toast';
import axiosInstance from '../../../../../Config/axios'
import SevenTwentySevenActionbuttons from './SevenTwentySevenActionbuttons'
import Card from '../ common/index'
import { getCardsTotal } from '../../../../../Config/function'
import Chat from '../../../../ChatingComponent/chatingComponent'
const PlayGame = ({ gameInfo, socket }) => {
    gsap.registerPlugin(Flip);

    const usersRef = useRef([])
    const gameAddress = gameInfo?.gameAddress
    const [gameStatus, setGameStatus] = useState(gameInfo?.round || 'starting')
    const [gameBetStatus, setGameBetStatus] = useState({
        buttons: [],
        currentPlayer: 0,
        betAmount: 0,
        pot: 0,
        userBalances: [],
        foldedPlayers: [],
        leftPlayers: [],
        bustedPlayers: [],
        playerCards: [],
        timer: 30
    })
    const [currentMoveInfo, setCurrentMoveInfo] = useState({
        card: {},
        currentPlayer: 0,
        pot: 0,
        userBalances: [],
        maxBet: 0,
        foldedPlayers: [],
        leftPlayers: [],
        bustedPlayers: [],
        playerCards: [],
        timer: 30
    })


    const userData = useSelector(state => state.auth.userData)
    const joinedPlayers = gameInfo?.players || []


    let allPlayers = []

    const myProfile = joinedPlayers.find((player) => player.userId == userData?.id)
    const otherPlayers = joinedPlayers.filter((player) => player.userId != userData?.id)

    if (myProfile) {
        allPlayers = [myProfile, ...otherPlayers]
    } else {
        allPlayers = otherPlayers
    }

    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (allPlayers.length == 1) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        if (allPlayers.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (allPlayers.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (allPlayers.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (allPlayers.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform  translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 right-0 transform  -translate-x-1/2'
            if (index == 4) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allPlayers.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 '
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2'
            if (index == 5) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allPlayers.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2 translate-y-1/4'
        } else if (allPlayers.length == 8) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center -bottom-0 right-0 transform -translate-x-1/4 translate-y-1/2 '

        } else if (allPlayers.length == 9) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
            if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
        }
        return className
    }

    const animateFlowToPot = (playerId, targetId, isCard) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);

        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        // Create a card element (or image)
        let card = null

        if (isCard) {
            card = document.createElement('span');
            card.className = 'card h-20 w-14 bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none border-2 border-gray-900 to-red-200 rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
            // card.src = blankCard;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        } else {
            card = document.createElement('img');
            card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle ';
            card.src = coins;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        }

        document.body.appendChild(card);
        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };

    const animateFlow = (playerId, targetId, innerText) => {
        console.log("Animate Flow called")
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);

        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        let card = null


        card = document.createElement('span');
        card.className = 'absolute bg-red-500  text-white bg-transparent text-5xl left-1/2 transform translate-x-1/2 font-extrabold capitalize rounded-full rounded-md flex items-center justify-center text-center';
        card.style.top = `${playerRect.top + window.scrollY}px`;
        card.style.left = `${playerRect.left + window.scrollX}px`;

        card.innerText = innerText || '';


        document.getElementById("seven_tws_table").appendChild(card);
        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };


    const fetchPlayerBetAction = async () => {
        try {
            const bodyData = { gameAddress: gameInfo?.gameAddress }
            const response = await axiosInstance.post('/api/game/seven-twenty-seven/bet-status', bodyData);

            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setGameBetStatus(responseData.data)
            }

        } catch (error) {
            console.log(error)
            hotToast.error('Something went wrong', { duration: 3000, position: 'bottom-center' })
        }
    }

    const fetchPlayetMoveAction = async () => {
        try {
            const bodyData = { gameAddress: gameInfo?.gameAddress }
            const response = await axiosInstance.post('/api/game/seven-twenty-seven/move-status', bodyData);

            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setCurrentMoveInfo(responseData.data)
            }

        } catch (error) {
            console.log(error)
            hotToast.error('Something went wrong', { duration: 3000, position: 'bottom-center' })
        }
    }

    const handleLeaveGame = async () => {
        try {
            const bodyData = { gameAddress: gameInfo?.gameAddress }
            const response = await axiosInstance.post('/api/game/seven-twenty-seven/leave', bodyData);

            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                hotToast.success(responseData.message, { duration: 3000, position: 'bottom-center' })
                window.location.href = '/all-game-page'
            }

        } catch (error) {
            console.log(error)
            hotToast.error('Something went wrong', { duration: 3000, position: 'bottom-center' })
        }

    }


    useEffect(() => {
        if (!socket) return;
        socket.on('betPlayed', (data) => {
            const { isBetFinished, userId, isStarted } = data;
            animateFlowToPot(`stws_player_${data.userId}`, 'stws_pot_money_box');

            if (isBetFinished) {
                fetchPlayerBetAction()
                hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                servedCardsToPlayers()

                const totalTimeToServeCards = 1000 * allPlayers.length * 2;
                setTimeout(() => {
                    setGameStatus('playing');
                    fetchPlayetMoveAction();
                }, totalTimeToServeCards);


            } else if (data.gameStatus == 'betting') {
                if (isStarted) {
                    hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                    setGameStatus('playing');
                    fetchPlayetMoveAction();
                } else {
                    setGameStatus(data.gameStatus);
                    fetchPlayerBetAction();
                }
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        })

        socket.on('movePlayed', (data) => {
            const userData = JSON.parse(localStorage.getItem('userData'));
            const { isRoundFinished, move } = data;
            animateFlow(`stws_player_${data.userId}`, 'stws_pot_money_box', data.move);

            setTimeout(() => {
                if (move === 'draw') {
                    let targetId = userData?.id == data.userId ? 'current_player_cards' : `stws_player_${data.userId}`;
                    animateFlowToPot(`stws_pot_money_box`, targetId, true);
                    setTimeout(() => {
                        isRoundFinished ? fetchPlayerBetAction() : fetchPlayetMoveAction();
                        if (isRoundFinished) {
                            setGameStatus('betting');
                        }
                    }, 1000);
                } else {
                    isRoundFinished ? fetchPlayerBetAction() : fetchPlayetMoveAction();
                    if (isRoundFinished) {
                        setGameStatus('betting');
                    }
                }
            }, 1000);


        })

        socket.on('opponentLeft', (data) => {
            if (data.gameStatus == 'betting') {
                fetchPlayerBetAction();
            } else if (data.gameStatus == 'playing') {
                fetchPlayetMoveAction();
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        });

        socket.on('cardFaceChanged', (data) => {
            console.log("Card Face Changed", data)
            if (data.round == 'betting') {
                fetchPlayerBetAction();
            } else if (data.round == 'playing') {
                fetchPlayetMoveAction();
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        })

        return () => {
            socket.off('betPlayed');
            socket.off('movePlayed');
            socket.off('opponentLeft');
            socket.off('cardFaceChanged');
        }
    }, [gameInfo])

    const servedCardsToPlayers = () => {
        const totalPlayers = allPlayers.length;
        const dealer = document.getElementById('stws_pot_money_box');

        allPlayers.forEach((_, index) => {
            for (let round = 0; round < 2; round++) {
                setTimeout(() => {
                    const card = document.createElement('div');
                    card.className = 'card h-20 w-14 bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none  border-2 border-gray-900 to-red-200 rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
                    dealer.appendChild(card);

                    const dealerRect = dealer.getBoundingClientRect();
                    const userIndex = index % totalPlayers;
                    const userRect = usersRef.current[userIndex].getBoundingClientRect();
                    const cardRect = card.getBoundingClientRect();

                    const x = userRect.left + (userRect.width - cardRect.width) / 2 - dealerRect.left + window.scrollX;
                    const y = userRect.top + (userRect.height - cardRect.height) / 2 - dealerRect.top + window.scrollY;

                    gsap.to(card, {
                        duration: 1.5,
                        x: x,
                        y: y,
                        ease: "power2.inOut",
                        onComplete: () => {
                            card.remove();
                        }
                    });
                }, index * 1000 + round * 500);
            }
        });
    }


    useEffect(() => {
        if (gameStatus === 'starting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'betting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'playing') {
            fetchPlayetMoveAction();
        }
    }, [gameStatus])

    let userBalances = gameBetStatus?.userBalances || []
    let pot = gameBetStatus?.pot || 0
    let maxBet = gameBetStatus?.maxBet || 0
    let foldedPlayers = gameBetStatus?.foldedPlayers || []
    let leftPlayers = gameBetStatus?.leftPlayers || []
    let bustedPlayers = gameBetStatus?.bustedPlayers || []
    let currentPlayer = gameBetStatus?.currentPlayer || 0
    let playerCards = gameBetStatus?.playerCards || []
    let timer = gameBetStatus?.timer || 30
    if (gameStatus === 'playing') {
        userBalances = currentMoveInfo?.userBalances || []
        maxBet = currentMoveInfo?.maxBet || 0
        pot = currentMoveInfo?.pot || 0
        userBalances = currentMoveInfo?.userBalances || []
        foldedPlayers = currentMoveInfo?.foldedPlayers || []
        leftPlayers = currentMoveInfo?.leftPlayers || []
        bustedPlayers = currentMoveInfo?.bustedPlayers || []
        currentPlayer = currentMoveInfo?.currentPlayer || 0
        playerCards = currentMoveInfo?.playerCards || []
        timer = currentMoveInfo?.timer || 30
    }


    let showCards = true
    if (gameStatus === 'starting') showCards = false

    let myCards = []

    if (myProfile) {
        const myPlayerCards = playerCards.find((item) => item.userId === myProfile.userId)
        myCards = myPlayerCards?.cards || []
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('seven_tws_table');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }

    const handleCardFaceChange = async (card) => {
        return
        try {
            if (card.isOpen) return;
            const formData = new FormData();
            formData.append('gameAddress', gameInfo?.gameAddress);
            formData.append('card', JSON.stringify(card));

            const response = await axiosInstance.post('/api/game/seven-twenty-seven/change-card-face', formData);

            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 5000, position: 'bottom-center' })
            }

        } catch (error) {
            hotToast.error('Failed to change card face', { duration: 5000, position: 'bottom-center' })
        }
    }

    const RenderCardTotal = ({ cards }) => {
        const aceCard = cards.filter((card) => card.Rank === 'A');
        const otherCards = cards.filter((card) => card.Rank !== 'A');

        let total = getCardsTotal(otherCards);


        if (aceCard && aceCard.length > 0) {
            return `${total + aceCard.length * 1} or ${total + aceCard.length * 11}`
        }

        return total

    }

  const chatingComponentMemo = useMemo(() => <Chat userId={userData?.id}  roomId={gameAddress} />, [])


    return (
        <div id='seven_tws_table' className='relative game container flex flex-col items-center h-full'>
            {chatingComponentMemo}
            <MenuActions isInGame={myProfile} gameStatus={gameStatus} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo} />
            <div className=' h-[55%] p-2 bg-gray-200 border-2  mt-20 container rounded-full '>
                <div className="relative p-2 h-full rounded-full border-2 bg-slate-900">
                    <div className="h-full rounded-full border-2" style={{
                        backgroundColor: '#222222'
                    }}>
                        <div id='stws_pot_money_box' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <PotMoney pot={pot} />
                        </div>
                        {allPlayers.map((player, index) => {
                            return (
                                <div id={`stws_player_${player.userId}`} key={index} className={getClassName(index)} ref={el => usersRef.current[index] = el}>
                                    <div className='stws-lo-player flex items-center flex-col shadow-2xl	'>
                                        <Player index={index} totalPlayers={allPlayers.length} gameStatus={gameStatus} timer={timer} userBalances={userBalances} foldedPlayers={foldedPlayers} leftPlayers={leftPlayers} playerCards={playerCards} currentPlayer={currentPlayer} showCards={showCards} isWaiting={true} player={player} bustedPlayers={bustedPlayers}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div id='current_player_cards' className=' h-auto p-3  mt-4 container flex flex-col w-full items-center justify-center'>
                {showCards && <div className='flex flex-col items-center justify-center mt-2 w-full'>
                    <span className='text-white font-medium text-xs'>Total : <RenderCardTotal cards={myCards}/></span>
                </div>}
                <div className='flex flex-row w-full justify-between md:justify-center lg:justify-center xl:justify-center 2xl:justify-center items-center mt-3'>
                    <div className='flex flex-row gap-1'>
                        {showCards && myCards.map((card, index) => {
                            return (
                                <div onClick={() => handleCardFaceChange(card)} role='button' className={`flex flex-col justify-between ${card.isOpen && "border-2 border-black shadow-black shadow-2xl "} items-center rounded-md`}>
                                    <Card className={'fade-in-up h-20 w-14 rounded-md shadow-2xl shadow-gray-400'} key={index} card={card} />
                                    {card.isOpen && <span className='text-xs text-white font-extrabold'>Faceup</span>}
                                </div>
                            )
                        })}
                    </div>
                    <SevenTwentySevenActionbuttons maxBet={maxBet} pot={pot} gameStatus={gameStatus} currentMoveInfo={currentMoveInfo} gameBetStatus={gameBetStatus} />
                </div>
            </div>
        </div>
    )
}

export default PlayGame
