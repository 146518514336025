import React, { useState } from 'react'
import MenuActions from '../PlayGame/MenuActions'
import Player from '../PlayGame/Player'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../../../../Config/function'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ToastContent from '../../../../../CommonComponent/Toast'
import axiosInstance from '../../../../../Config/axios'
import StartGameAction from './StartGameAction'
import { useNavigate } from 'react-router-dom'
import hotToast from 'react-hot-toast'
import Chat from '../../../../ChatingComponent/chatingComponent'

const WaitingForPlayers = ({ gameInfo, fetchGameInfo }) => {
    const userData = useSelector(state => state.auth.userData)
    const gameCreated = gameInfo?.createdBy
    const navigate = useNavigate()

    const gameAddress = gameInfo?.gameAddress
    const joinedPlayers = gameInfo?.players || []
    const minPlayers = gameInfo.minPlayers
    const maxPlayers = gameInfo.maxPlayers

    const myProfile = joinedPlayers.find(player => player.userId === userData?.id)
    const restOtherPlayers = joinedPlayers.filter(player => player.userId !== userData?.id)

    const remainingPlayers = Array(maxPlayers - joinedPlayers.length).fill({
        address: '0x',
        joined: false
    })

    let players = []
    if (myProfile) {
        players = [myProfile, ...restOtherPlayers]
    } else {
        players = [...joinedPlayers]
    }


    const isInGame = !isEmpty(myProfile)


    const handleJoinGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/join', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 5000, position: 'bottom-center' })
            } else {
                const data = { slug: 'highlow', gameAddress }
                localStorage.setItem('currentJoinedGame', JSON.stringify(data))
            }


        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    let gameCanStart = false
    const totalPlayers = joinedPlayers.length
    if (totalPlayers >= minPlayers) {
        gameCanStart = true
    }


    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (players.length == 1) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        if (players.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (players.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (players.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (players.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/4 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-1/4 transform translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 8) { }

        return className
    }

    const handleLeaveGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/leave', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                navigate('/all-game-page');
            }

        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('hilo_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }



    return (
        <div id='hilo_screen' className='game relative flex flex-col items-center h-full'>
            {/* <Chat userId={userData?.id} roomId={gameAddress} /> */}
            <MenuActions gameStatus={gameInfo?.status} isInGame={isInGame} handleJoinGame={handleJoinGame} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} />
            <div className='w-full h-[75%] p-3 bg-gray-900 border-4  mt-10 container rounded-2xl '>
                <div className="relative rounded-lg w-full h-full  bg-green-900 border-4">


                    {players.map((player, index) => {
                        return (
                            <div key={index} className={getClassName(index)}>
                                <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                                    <Player isWaiting={true} player={player} />
                                </div>
                            </div>
                        );

                    })}
                    {!isInGame && <div className="absolute left-1/2 w-100  right-1/2 top-1/2 flex transform -translate-x-1/2 -translate-y-1/2 items-center justify-center  ">
                        <button onClick={handleJoinGame} className='bg-gray-200 mr-2 text-black px-5 py-2.5 rounded-md btn text-center text-xs font-extrabold'>Join Game</button>
                    </div>}


                </div>
            </div>

            {/* <div className='w-full h-auto mt-5 container rounded-lg'>
                <div className='flex items-center justify-center h-full'>
                    {!gameCanStart && (gameCreated === userData?.id) && <span className='text-white font-bold text-md'>
                        {`Game can be started when there are minimum ${minPlayers} players`}
                    </span>}
                    {gameCanStart ? <StartGameAction fetchGameInfo={fetchGameInfo} />
                            : <span className='text-white font-bold '>
                                {`Waiting to start the game`}
                            </span>
                    }
                </div>
            </div> */}
            {gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
                <div className='flex items-center justify-center h-full'>
                    {isInGame && !gameCanStart && (gameCreated === userData?.id) && <span className='text-white font-bold text-md'>
                        {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
                    </span>}
                    {!isInGame && (
                        <button
                            onClick={handleJoinGame}
                            className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
                        >
                            Join Game
                        </button>
                    )}
                    {isInGame && gameCanStart && (gameCreated === userData?.id
                        ? <StartGameAction fetchGameInfo={fetchGameInfo} />
                        : <span className='text-white font-bold '>
                            {`Waiting to start the game`}
                        </span>
                    )}
                </div>
            </div>}

            {!gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
                <div className='flex items-center justify-center h-full'>
                    {!gameCanStart && <span className='text-white font-bold text-md'>
                        {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
                    </span>}
                    {!isInGame && (
                        <button
                            onClick={handleJoinGame}
                            className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
                        >
                            Join Game
                        </button>
                    )}
                    {gameCanStart && <StartGameAction fetchGameInfo={fetchGameInfo} />}
                </div>
            </div>}

        </div>
    )
}

export default WaitingForPlayers
