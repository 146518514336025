import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import BlurOnIcon from '@mui/icons-material/BlurOn';
import Card from "../Common/Cards/index"
const Player = ({ player, index, totalPlayers, gameStatus, foldedPlayers, leftPlayers, userBalances, playerCards, currentPlayer, handleToggleCard, selectedCards, timer, gameBetStatus }) => {

  const userData = useSelector(state => state.auth.userData)

  const getCardsClassName = () => {

    if (totalPlayers === 2) {
      if (index === 0) return "-top-20 left-1/2 transform -translate-x-1/2"
      if (index === 1) return "-bottom-16"
    }
    if (totalPlayers === 3) {
      if (index === 0) return "-top-20 left-1/2 transform -translate-x-1/2"
      if (index === 1) return "left-16 top-1/2 transform -translate-y-1/2 rotate-90"
      if (index === 2) return "right-20 top-1/2 transform -translate-y-1/2 rotate-90"
    }

    if (totalPlayers === 4) {
      if (index === 0) return "-top-20 left-1/2 transform -translate-x-1/2"
      if (index === 1) return "left-16 top-1/2 transform -translate-y-1/2 rotate-90"
      if (index === 2) return "-bottom-16"
      if (index === 3) return "right-20 top-1/2 transform -translate-y-1/2 rotate-90"
    }

    if (totalPlayers === 5) {
      if (index === 0) return "-top-20 left-1/2 transform -translate-x-1/2"
      if (index === 1) return " rotate-45 left-1/4 bottom-1/2 transform translate-x-1/4 -translate-y-1/2"
      if (index === 2) return "-rotate-45 left-1/4 top-1/2 transform translate-x-1/4 translate-y-1/2"
      if (index === 3) return "rotate-45 right-1/2 top-1/2 transform -translate-x-1/4 translate-y-1/2"
      if (index === 4) return "-rotate-45 right-1/4 bottom-1/2 transform -translate-x-1/2 -translate-y-1/2"
    }
    if (totalPlayers === 6) {
      if (index === 0) return "-top-20 left-1/2 transform -translate-x-1/2"
      if (index === 1) return " rotate-45 left-1/4 bottom-1/2 transform translate-x-1/4 -translate-y-1/2"
      if (index === 2) return "-rotate-45 left-1/4 top-1/2 transform translate-x-1/4 translate-y-1/2"
      if (index === 3) return "-bottom-16"
      if (index === 4) return "rotate-45 right-1/2 top-1/2 transform -translate-x-1/4 translate-y-1/2"
      if (index === 5) return "-rotate-45 right-1/4 bottom-1/2 transform -translate-x-1/2 -translate-y-1/2"

    }
    if (totalPlayers === 7) {
      if (index === 0) return "-top-20 left-1/2 transform -translate-x-1/2"
      if (index === 1) return " left-1/2 bottom-1/2 transform translate-x-1/4 -translate-y-1/2"
      if (index === 2) return "left-16 top-1/2 transform -translate-y-1/2 rotate-90"
      if (index === 3) return "left-1/2 top-1/2 transform translate-x-1/4 translate-y-1/2"
      if (index === 4) return "right-1/2 top-1/2 transform -translate-x-1/4 translate-y-1/2"
      if (index === 5) return "right-16 top-1/2 transform -translate-y-1/2 rotate-90"
      if (index === 6) return "right-1/2 bottom-1/2 transform -translate-x-1/4 -translate-y-1/2"

    }
  }

  const currentPlayerCards = playerCards?.find(playerCard => playerCard.userId === player.userId)?.cards || []

  const playerBalance = userBalances?.find(userBalance => userBalance?.userId === player.userId) || {}


  const isFolded = foldedPlayers.includes(player.userId)
  const isLeft = leftPlayers.includes(player.userId)
  const isPlayerMove = ((player?.userId == currentPlayer) && gameStatus !== 'starting')


  const isTimer = timer > 0

  const RenderTimer = () => {
    const [timeLeft, setTimeLeft] = useState(timer)


    useEffect(() => {
      if (!isTimer) return;

      const interval = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [timeLeft])


    if (gameStatus === 'starting') return null;
    // if (gameBetStatus.round === 'showdown') return null;
    if (gameBetStatus.round === 'starting') return null;
    if (timeLeft <= 0) return null;

    return <span className='hi-lo-player__profile absolute rounded-full h-8 w-8 -bottom-4 border-red-800 font-extrabold flex items-center justify-center bg-red-800 text-white shadow-2xl'>
      <span className='text-xs'>{timeLeft}</span>
    </span>
  }

  const renderTimerMemo = useMemo(() => <RenderTimer />, [timer, gameStatus, gameBetStatus])
  return (
    <>
      <div className='relative five_card_player flex items-center flex-col'>
        {isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-36  ${player.active && "active"}`} />}
        {!isFolded && isLeft && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile h-20 w-36  ${player.active && "active"}`} />}
        {!isFolded && !isLeft && <img src={player?.profileUrl} alt='profile' className={`hi-lo-player__profile h-12 w-12 rounded-full border-2 border-white  ${player.active && "active"}`} />}
        
        
        <div className={`${isPlayerMove && "py-2 px-3 bg-green-400 rounded-2xl relative"}`}>
          <div className='relative flex flex-col items-center justify-center bg-black font-extrabold px-3 text-center border-2 rounded-md'>
          {isTimer && currentPlayer === player.userId && renderTimerMemo}
            <div className='name text-white mb-1 text-xs flex flex-row'>{
              player.userName ? player.userName :
                formatAddress(player.address, 3)
            }
            </div>
            {((player?.userId === userData?.id) && playerBalance.amount) ? <div className='balance text-white text-xs'>({Number(playerBalance.amount).toFixed(2)}) Sol</div> : null}
          </div>
        </div>

        {<div className={`flex items-center justify-center gap-0.5 absolute ${getCardsClassName(index)}`}>
          {gameStatus !== 'starting' && !isFolded && !isLeft && currentPlayerCards.map((card, index) => (
            card['Rank'] ? <span onClick={() => handleToggleCard(index, player.userId)} id={`player_card_${player.userId}_${index}`} role='button' className={`relative fade-in-up flex flex-col items-center justify-between ${(selectedCards.includes(index) && currentPlayer == player?.userId) && 'scale-125'}`} >
              {(player.userId === userData?.id) && (<span className='absolute -top-4 font-semibold w-full text-white text-xs text-center'>{card.isOpen ? 'Up' : 'Down'}</span>)}
              <Card card={card} className={"rounded-sm h-14 w-10"} textSize={'text-xs'} fontSize={12}/>
            </span> : <>
              <span id={`player_card_${player.userId}_${index}`} role='button' className={`rounded-sm fade-in-up h-14 w-10 bg-red-500 border-2 flex items-center justify-center`} >
                <BlurOnIcon className='text-white text-2xl ' />
              </span>
            </>
          ))}
        </div>}


      </div>
    </>
  )
}

export default Player
