import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import { useSelector } from 'react-redux'
import Card from "../Common/Cards/index"
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
const Player = ({ player, winner, totalPlayers, index, allLeftExceptWinner, result }) => {

  const userData = useSelector(state => state.auth.userData)

  const getCardsClassName = (index) => {
    if (totalPlayers === 2) {
      if (index === 0) return 'absolute -top-16 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
    }
    if (totalPlayers === 3) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute left-5 top-0 rotate-90'
      if (index === 2) return 'absolute right-5 rotate-90 top-0'
    }
    if (totalPlayers === 4) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute left-5 top-0 rotate-90'
      if (index === 2) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
      if (index === 3) return 'absolute right-5 rotate-90 top-0'
    }

    if (totalPlayers === 5) {
      if (index === 0) return 'absolute -top-16 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-20 left-1  translate-y-1/4'
      if (index === 2) return 'absolute  -bottom-20 left-1 translate-y-1/4'
      if (index === 3) return 'absolute -bottom-20  right-1 translate-y-1/4'
      if (index === 4) return 'absolute -top-20 right-1 translate-y-1/4'
    }

    if (totalPlayers === 6) {
      if (index === 0) return 'absolute -top-16 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-20 left-1  translate-y-1/2'
      if (index === 2) return 'absolute  -bottom-20 left-1 translate-y-1/4'
      if (index === 3) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
      if (index === 4) return 'absolute -bottom-20  right-1 translate-y-1/4'
      if (index === 5) return 'absolute -top-20 right-1 translate-y-1/4'
    }
    if (totalPlayers === 7) {
      if (index === 0) return 'absolute -top-16 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-20 left-1  translate-y-1/2'
      if (index === 2) return 'absolute  rotate-90 left-1 translate-y-1/4'
      if (index === 3) return 'absolute -bottom-20 left-1 translate-y-1/4'
      if (index === 4) return 'absolute -bottom-20 right-1 translate-y-1/4'
      if (index === 5) return 'absolute  rotate-90 right-1 translate-y-1/4'
      if (index === 6) return 'absolute -top-20 right-1 translate-y-1/4'
    }
    if (totalPlayers === 8) {
      if (index === 0) return 'absolute -top-16 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-20 left-1  translate-y-1/2'
      if (index === 2) return 'absolute  rotate-90 left-1 translate-y-1/4'
      if (index === 3) return 'absolute -bottom-20 left-1 translate-y-1/4'
      if (index === 4) return 'absolute -bottom-20  left-1/2 transform -translate-x-1/2'
      if (index === 5) return 'absolute -bottom-20 right-1 translate-y-1/4'
      if (index === 6) return 'absolute  rotate-90 right-1 translate-y-1/4'
      if (index === 7) return 'absolute -top-20 right-1 translate-y-1/4'
    }
    if (totalPlayers === 9) {
      if (index === 0) return 'absolute -top-16 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-20 left-1  translate-y-1/2'
      if (index === 2) return 'absolute  rotate-90 left-1 translate-y-1/4'
      if (index === 3) return 'absolute -bottom-20 left-1 translate-y-1/4'
      if (index === 4) return 'absolute -bottom-20  left-1/2 transform -translate-x-1/2'
      if (index === 5) return 'absolute -bottom-20  left-1/2 transform -translate-x-1/2'
      if (index === 6) return 'absolute -bottom-20 right-1 translate-y-1/4'
      if (index === 7) return 'absolute  rotate-90 right-1 translate-y-1/4'
      if (index === 8) return 'absolute -top-20 right-1 translate-y-1/4'
    }



  }

  const highLightWinner = (player) => {
    if (!result) return false
    if (player?.type == 'deposit') return true
    return false
  }

  const Cards = () => {

    const allCards = player.cards || []
    if (allLeftExceptWinner) return null
    return allCards.map((card, index) => (
      <div className='flex flex-col items-center justify-center'>
        <Card className={`h-14 w-10 ${card?.showDown ? "shadow-purple-200 shadow-2xl" : "opacity-30"} rounded-sm`} key={index} card={card} index={index} textSize={'text-xs'} fontSize={12} />
      </div>
    ))
  }

  const winnerCardHand = () => {
    if (player.isFolded) return null
    if (player.isLeft) return null

    if (allLeftExceptWinner) return null
    if (!result) return null
    if (player.isLeft) return null
    const { winningHands } = result

    if (!winningHands) return null
    const winnerPlayer = winningHands.find(winner => winner.userId === player.userId)

    if (!winnerPlayer) return null
    const winningHandsIndex = winningHands.findIndex(winner => winner.userId === player.userId)
    if (winningHandsIndex === -1) return null


    if (player.type == 'deposit') {
        return <span className='capitalize'>{winnerPlayer['rank']}  {winnerPlayer?.hand}</span>
    }

    return <span className='capitalize'>{winnerPlayer['rank']}  {winnerPlayer?.hand}</span>
  }

  const PlayerGameAmount = ({ player }) => {
    let amount = player?.totalAmount || 0
    amount = Number(amount).toFixed(2)

    if (player.type === 'withdrawal') {
      return <span className='text-xs text-red-600 font-bold'> - {amount} Sol</span>
    }
    if (player.type === 'deposit') {

      let playerAmount = player?.amount || 0
      let playerRestAmount = player?.restAmount || 0

      playerAmount = Number(playerAmount).toFixed(2)
      playerRestAmount = Number(playerRestAmount).toFixed(2)

      playerAmount = Number(playerAmount)
      playerRestAmount = Number(playerRestAmount)

      let totalAmount = playerRestAmount - playerAmount

      totalAmount = Number(totalAmount).toFixed(2)
      totalAmount =Math.abs(totalAmount)
      totalAmount = Number(totalAmount).toFixed(2)

      let playerTotalAmount = Number(totalAmount) + Number(amount)
      playerTotalAmount = Number(playerTotalAmount).toFixed(2)

      if (player.userId == userData?.id) {
        return <span className='text-xs font-extrabold text-green-600 uppercase'> + {playerTotalAmount} Sol</span>
      }

      return <span className='text-xs font-extrabold text-green-600 uppercase'> + {amount} Sol</span>
    }
  }


  return (
    <div className='relative hi-lo-player flex items-center flex-col	'>

      {/* (!player.isFolded) && (!player.isLeft) &&  */}
      {(!player.isFolded) && (!player.isLeft) && <div className={` flex items-center justify-center gap-1.5 ${getCardsClassName(index)}`}>
        <Cards />
      </div>}
      {player.isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-12 w-32  ${winner.userId == player.userId && ""}`} />}
      {player.isLeft && !player.isFolded && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile  h-16 w-16  p-1/2 ${winner.userId == player.userId && ""}`} />}
      {/* {!player.isFolded && !player.isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 ${winner.userId == player.userId && ""}`} />} */}



      <div className={`relative flex flex-row items-center justify-between ${winner?.userId === player.userId ? "bg-green-400 text-black py-3" : 'bg-black text-white w-full px-2 py-0.5'} font-extrabold text-center border-2 rounded-md`}>
        <img id={`seven_card_result_player_${player.userId}`} src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-8 w-8 border border-white p-1/2 ${winner.userId == player.userId && ""}`} />
        <div className='flex flex-col justify-start items-start ml-2'>
          <div className='name mb-0.5 text-xs'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>
          <PlayerGameAmount player={player} />
        </div>
        
      </div>
      {<span className='absolute font-bold  text-white -bottom-6 bg-purple-600 skew-x-12 py-0 px-4 w-max text-center'>
          {/* {highLightWinner(player) && <EmojiEventsIcon className='mr-2' />} */}
          {winnerCardHand()}
        </span>}

    </div>
  )
}

export default Player
