import React from 'react'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import BustImage from '../../../../../assets/img/busted.png'
import { formatAddress, getCardsTotal } from '../../../../../Config/function'
import Card from '../ common/index'

const Player = ({ player, totalPlayers, index }) => {

  const newPlayerCard = player.cards || []

  const getCardsClassName = (index) => {
    if (totalPlayers === 2) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -bottom-24 left-1/2 transform -translate-x-1/2'
    }
    if (totalPlayers === 3) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute left-10 top-1/2 transform  rotate-90'
      if (index === 2) return 'absolute right-10 top-1/2 transform  rotate-90'
    }
    if (totalPlayers === 4) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute left-10 top-1/2 transform  rotate-90'
      if (index === 2) return 'absolute -bottom-24 left-1/2 transform -translate-x-1/2'
      if (index === 3) return 'absolute right-10 top-1/2 transform rotate-90'
    }

    if (totalPlayers === 5) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-16 transform -translate-x-1/2' 
      if (index === 2) return 'absolute  -bottom-24 -translate-x-1/2'
      if (index === 3) return 'absolute  -bottom-24 right-0 translate-x-1/2'
      if (index === 4) return 'absolute -top-16 transform -translate-x-1/2'
    }

    if (totalPlayers === 6) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-16 transform -translate-x-1/2' 
      if (index === 2) return 'absolute  -bottom-24 -translate-x-1/2'
      if (index === 3) return 'absolute -bottom-24 left-1/2 transform -translate-x-1/2'
      if (index === 4) return 'absolute  -bottom-24 right-0 translate-x-1/2'
      if (index === 5) return 'absolute -top-16 transform -translate-x-1/2'
    }

    if (totalPlayers === 7) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-16 transform -translate-x-1/2' 
      if (index === 2) return 'absolute left-10 top-1/2 transform  rotate-90'
      if (index === 3) return 'absolute -bottom-24 left-1/2 transform -translate-x-1/2'
      if (index === 4) return 'absolute  -bottom-24 right-0 translate-x-1/2'
      if (index === 5) return 'absolute right-10 top-1/2 transform  rotate-90'
      if (index === 6) return 'absolute -top-16 transform -translate-x-1/2'
    }

    if (totalPlayers === 8) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-16 transform -translate-x-1/2' 
      if (index === 2) return 'absolute left-10 top-1/2 transform  rotate-90'
      if (index === 3) return 'absolute -bottom-24 left-1/2 transform -translate-x-1/2'
      if (index === 4) return 'absolute  -bottom-24 right-0 translate-x-1/2'
      if (index === 5) return 'absolute  -bottom-24 right-0 translate-x-1/2'
      if (index === 6) return 'absolute right-10 top-1/2 transform  rotate-90'
      if (index === 7) return 'absolute -top-16 transform -translate-x-1/2'
    }
    if (totalPlayers === 9) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -top-16 transform -translate-x-1/2' 
      if (index === 2) return 'absolute left-10 top-1/2 transform  rotate-90'
      if (index === 3) return 'absolute -bottom-24 left-1/2 transform -translate-x-1/2'
      if (index === 4) return 'absolute  -bottom-24 right-0 translate-x-1/2'
      if (index === 5) return 'absolute  -bottom-24 right-0 translate-x-1/2'
      if (index === 6) return 'absolute  -bottom-24 right-0 translate-x-1/2'
      if (index === 7) return 'absolute right-10 top-1/2 transform  rotate-90'
      if (index === 8) return 'absolute -top-16 transform -translate-x-1/2'
    }



  }


  const RenderCardTotal = ({ cards }) => {
    const aceCard = cards.filter((card) => card.Rank === 'A');
    const otherCards = cards.filter((card) => card.Rank !== 'A');

    let total = getCardsTotal(otherCards);

    if (aceCard && aceCard.length > 0) {
        return `${total + aceCard.length * 1} or ${total + aceCard.length * 11}`
    }

    return total

}


  return (
    <div className={`hi-lo-player flex items-center flex-col `}>
      <div className=' cards flex flex-row items-center justify-between gap-2 mb-0 relative'>
        {player.isFolded && <img src={FoldImage} alt='fold' className='w-full h-12' />}
        {player.isLeft && !player.isFolded && <img src={LeftImage} alt='left' className='w-28 h-12' />}
        {player.isBusted && <img src={BustImage} alt='busted' className='w-28 h-12' />}

        {!player.isBusted && !player.isFolded && !player.isLeft && <div className={` flex items-center justify-center gap-0.5 ${getCardsClassName(index)}`}>
          {newPlayerCard.map((card, index) => {
            return (
              <Card fontSize={12} textSize={'text-xs'} className={'fade-in-up h-14 w-9 rounded-xs'} key={index} card={card} />
            )
          })}
        </div>}
      </div>
      <div className={`flex flex-row items-center justify-center bg-black font-extrabold px-2 text-center border-2 rounded-md `}>
        {<img src={player?.profileUrl} alt='left' className='w-9 h-9 mr-2 rounded-full' />}
        <div className='flex flex-col items-start  name text-white mb-1 text-xs'>
          <span>{player.userName ? player.userName : formatAddress(player.address, 3)}</span>
          Total: <RenderCardTotal cards={newPlayerCard} />
        </div>
      </div>
    </div>
  )
}

export default Player
