import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../../../Config/axios'
import WaitingForPlayers from './WaitingForUser'
import PlayGame from './PlayGame'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ToastContent from '../../../../CommonComponent/Toast'
import socketIOClient from 'socket.io-client';
import { useSelector } from 'react-redux'
import { useSpeechSynthesis } from 'react-speech-kit';
import hotToast from 'react-hot-toast';
import PlayAgain from './PlayAgain'
import Chat from '../../../ChatingComponent/chatingComponent';
import { isEmpty } from '../../../../Config/function'


const ENDPOINT = process.env.REACT_APP_API_URL;

const HiLoCardGame = () => {

    const { speak } = useSpeechSynthesis();

    const { gameAddress } = useParams()
    const [gameInfo, setGameInfo] = useState({})
    const [socket, setSocket] = useState(null)
    const userData = useSelector(state => state.auth.userData)

    const fetchGameInfo = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/info', { gameAddress })

            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title="Error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                })
            } else {
                setGameInfo(responseData.data)
                if (socket) {
                    socket.emit('joinedGame', { userId: userData?.id, roomId: gameAddress })
                }
            }

        } catch (error) {
            toast.error(<ToastContent title="Error" message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
            })
        }
    }


    useEffect(() => {
        fetchGameInfo()
    }, [gameAddress, socket])



    useEffect(() => {
        const newSocket = socketIOClient(ENDPOINT);
        setSocket(newSocket);

        newSocket.on('opponentJoined', (data) => {
            fetchGameInfo()
        });

        newSocket.on('opponentLeft', (data) => {
            const userId = data.userId
            if (userId === userData?.id) {
                hotToast.success('You left the game!', { duration: 5000, position: 'bottom-center' })
            } else {
                hotToast.success('Opponent Left!', { duration: 5000, position: 'bottom-center' })
                fetchGameInfo()
            }
        })

        newSocket.on('gameStarted', (data) => {
            hotToast.success('Game Started!', { duration: 5000, position: 'bottom-center' })
            fetchGameInfo()
        });

        newSocket.on('gameOver', (data) => {
            setTimeout(() => {
                hotToast.success('Game Over! ', { duration: 5000, position: 'bottom-center' })
                fetchGameInfo()
            }, 4000);
        })


        return () => {
            newSocket.off('opponentJoined')
            newSocket.off('opponentLeft')
            newSocket.off('gameStarted')
            newSocket.off('gameOver')
        }

    }, [gameAddress])


    const currentPlayer = gameInfo?.players?.find(player => player.userId === userData?.id)

    const chatingComponent = useMemo(() => <Chat userId={userData?.id} roomId={gameAddress} />, [])

    if (gameInfo['status'] == 'pending') {
        return (
            <>
                {!isEmpty(currentPlayer) && chatingComponent}
                <WaitingForPlayers fetchGameInfo={fetchGameInfo} gameInfo={gameInfo} />
            </>
        )
    }

    if (gameInfo['status'] == 'started') {
        return (
            <>
                {!isEmpty(currentPlayer) && chatingComponent}
                <PlayGame gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />
            </>
        )
    }

    if (gameInfo['status'] == 'completed' && !currentPlayer) {
        return (
            <>
                {chatingComponent}
                <PlayAgain gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />
            </>
        )
    }

    if (gameInfo['status'] == 'completed' && currentPlayer) {
        return (
            <>
                {chatingComponent}
                <WaitingForPlayers fetchGameInfo={fetchGameInfo} gameInfo={gameInfo} />
            </>
        )
    }

}

export default HiLoCardGame
