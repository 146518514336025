import React, { useEffect, useMemo, useRef, useState } from 'react'
import MenuActions from '../Common/MenuActions'
import Player from './Player'
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { useSelector } from 'react-redux';
import coins from '../../../../../assets/img/games/coins_transparent.png';
import hotToast from 'react-hot-toast';
import axiosInstance from '../../../../../Config/axios';
import { useParams } from 'react-router-dom';
import FiveCardPokerActionButtons from "./FiveCardPokerActionButtons";
import PotMoney from '../Common/PotMoney';
import Chat from '../../../../ChatingComponent/chatingComponent';
import AllInWinnerAnimation from './AllInWinnerAnimation';
const PlayGame = ({ gameInfo, socket, fetchGameInfo }) => {
    gsap.registerPlugin(Flip);
    const usersRef = useRef([])

    const [gameStatus, setGameStatus] = useState(gameInfo?.round || 'starting')
    const [selectedCards, setSelectedCards] = useState([])
    const [isAllInWinning, setIsAllInWinning] = useState(false)
    const { gameAddress } = useParams()
    const userData = useSelector(state => state.auth.userData)

    const [gameBetStatus, setGameBetStatus] = useState({
        buttons: [],
        currentPlayer: 0,
        betAmount: 0,
        pot: 0,
        maxBet: 0,
        userBalances: [],
        foldedPlayers: [],
        leftPlayers: [],
        playerCards: [],
        timer: 0
    })

    const [currentMoveInfo, setCurrentMoveInfo] = useState({
        currentPlayer: 0,
        pot: 0,
        maxBet: 0,
        userBalances: [],
        foldedPlayers: [],
        leftPlayers: [],
        playerCards: [],
        timer: 0
    })

    const allPlayers = gameInfo.players || []

    let allNewPlayers = []
    const myProfile = allPlayers.find(player => player.userId === userData.id)
    if (myProfile) {
        const otherPlayers = allPlayers.filter(player => player.userId !== userData.id)
        allNewPlayers = [myProfile, ...otherPlayers]
    } else {
        allNewPlayers = allPlayers
    }

    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (allNewPlayers.length == 1) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        if (allNewPlayers.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (allNewPlayers.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (allNewPlayers.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (allNewPlayers.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform  translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 right-0 transform  -translate-x-1/2'
            if (index == 4) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allNewPlayers.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 '
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2'
            if (index == 5) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allNewPlayers.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2 translate-y-1/4'
        } else if (allNewPlayers.length == 8) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center -bottom-0 right-0 transform -translate-x-1/4 translate-y-1/2 '

        } else if (allNewPlayers.length == 9) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
            if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
        }
        return className
    }

    const servedCardsToPlayers = () => {
        const totalPlayers = allPlayers.length;
        const dealer = document.getElementById('stws_pot_money_box');
        const totalRounds = 5;
        const dealInterval = 300; // Time between each card deal

        for (let round = 0; round < totalRounds; round++) {
            allPlayers.forEach((_, index) => {
                setTimeout(() => {
                    const card = document.createElement('div');
                    card.className = 'card h-16 w-10 bg-gradient-to-r from-red-300 border-2 border-gray-900 to-red-300 border border-2 rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
                    dealer.appendChild(card);

                    const dealerRect = dealer.getBoundingClientRect();
                    const userIndex = index % totalPlayers;
                    const userRect = usersRef.current[userIndex].getBoundingClientRect();
                    const cardRect = card.getBoundingClientRect();

                    const x = userRect.left + (userRect.width - cardRect.width) / 2 - dealerRect.left + window.scrollX;
                    const y = userRect.top + (userRect.height - cardRect.height) / 2 - dealerRect.top + window.scrollY;

                    gsap.to(card, {
                        duration: 0.5,
                        x: x,
                        y: y,
                        ease: "power2.inOut",
                        onComplete: () => {
                            card.remove();
                        }
                    });
                }, round * totalPlayers * dealInterval + index * dealInterval);
            });
        }

    }

    const animateFlowToPot = (playerId, targetId, isCard) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);

        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        // Create a card element (or image)
        let card = null

        if (isCard) {
            card = document.createElement('span');
            card.className = 'card h-20 w-14 bg-red-400 rounded-md border-2 border-white rounded-md shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        } else {
            card = document.createElement('img');
            card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle ';
            card.src = coins;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        }

        document.body.appendChild(card);
        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };

    const animateFlow = (playerId, targetId, innerText) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);

        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        let card = null


        card = document.createElement('span');
        card.className = 'absolute bg-red-500  text-white bg-transparent text-5xl left-1/2 transform translate-x-1/2 font-extrabold capitalize rounded-full rounded-md flex items-center justify-center text-center';
        card.style.top = `${playerRect.top + window.scrollY}px`;
        card.style.left = `${playerRect.left + window.scrollX}px`;

        card.innerText = innerText || '';


        document.getElementById("five_card_poker_table").appendChild(card);
        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };


    const fetchPlayetMoveAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/5-card-poker/move-status', { gameAddress });

            const { data: responseData } = response;

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setCurrentMoveInfo(responseData.data);
            }

        } catch (error) {
            hotToast.error('Error while fetching player move action', { duration: 3000, position: 'bottom-center' })
        }
    }

    const fetchPlayerBetAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/5-card-poker/bet-status', { gameAddress });

            const { data: responseData } = response;

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setGameBetStatus(responseData.data);
            }

        } catch (error) {
            hotToast.error('Error while fetching player bet action', { duration: 3000, position: 'bottom-center' })
        }

    }

    useEffect(() => {
        if (!socket) return;
        socket.on('betPlayed', (data) => {
            const { isBetFinished, userId, isStarted, isAllInWinning } = data;
            animateFlowToPot(`stws_player_${data.userId}`, 'stws_pot_money_box');

            if (isBetFinished) {
                fetchPlayerBetAction();
                hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                servedCardsToPlayers()

                const totalTimeToServeCards = 1000 * allNewPlayers.length * 2;
                setTimeout(() => {
                    // setGameStatus('playing');
                    setGameStatus('betting');
                    fetchPlayerBetAction();
                    // fetchPlayetMoveAction();
                }, totalTimeToServeCards);


            } else if (data.gameStatus == 'betting') {
                if (isStarted) {
                    hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                    setGameStatus('playing');
                    fetchPlayetMoveAction();
                } else {
                    setGameStatus(data.gameStatus);
                    fetchPlayerBetAction();
                }
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }

            if (isAllInWinning) {
                setIsAllInWinning(true);
                setTimeout(() => {
                    fetchGameInfo()
                }, 5000);
            }
        })

        socket.on('movePlayed', (data) => {
            const { isAllInWinning } = data
            const userData = JSON.parse(localStorage.getItem('userData'));
            const { isRoundFinished, move, selectedCards, userId, gameStatus } = data;
            setSelectedCards([]);
            animateFlow(`stws_player_${data.userId}`, 'stws_pot_money_box', data.move);
            if (move === 'draw') {
                if (isRoundFinished) {
                    setGameStatus('betting');
                    fetchPlayerBetAction();
                } else {
                    fetchPlayetMoveAction();
                }
                for (let i = 0; i < selectedCards.length; i++) {
                    const cardId = `player_card_${userId}_${selectedCards[i]}`;
                    const cardElement = document.getElementById(cardId);
                    if (cardElement && userId === userData.id) {
                        cardElement.style.opacity = 0;
                        animateFlowToPot(cardId, 'stws_pot_money_box', true);
                    } else {
                        setTimeout(() => {
                            animateFlowToPot(`stws_player_${userId}`, 'stws_pot_money_box', true);
                        }, 0.7 * (i) * 1000);
                    }
                }

                for (let i = 0; i < selectedCards.length; i++) {
                    setTimeout(() => {
                        const cardId = `player_card_${userId}_${selectedCards[i]}`;
                        const cardElement = document.getElementById(cardId);
                        if (cardElement && userId === userData.id) {
                            cardElement.style.opacity = 1;
                            animateFlowToPot('stws_pot_money_box', cardId, true);
                        } else {
                            animateFlowToPot('stws_pot_money_box', `stws_player_${userId}`, true);
                        }
                        if (i === selectedCards.length - 1) {
                            setSelectedCards([]);
                            
                        }
                    }, 0.7 * (selectedCards.length + i + 1) * 1000);
                }
            } else if (move==='pass') {
                if (isRoundFinished) {
                    setGameStatus('betting');
                    fetchPlayerBetAction();
                } else {
                    fetchPlayetMoveAction();
                }
            } else {
                if (isRoundFinished) {
                    setGameStatus('betting');
                    fetchPlayerBetAction();
                } else {
                    fetchPlayetMoveAction();
                }
            }
            if (isAllInWinning) {
                setIsAllInWinning(true);
                setTimeout(() => {
                    fetchGameInfo()
                }, 5000);
            }


        })

        socket.on('opponentLeft', (data) => {
            if (gameStatus == 'betting') {
                fetchPlayerBetAction();
            } else if (gameStatus == 'playing') {
                fetchPlayetMoveAction();
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        });

        return () => {
            socket.off('betPlayed');
            socket.off('movePlayed');
            socket.off('opponentLeft');
        }
    }, [gameInfo])

    useEffect(() => {
        if (gameStatus === 'starting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'betting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'playing') {
            fetchPlayetMoveAction();
        }
    }, [gameInfo, gameStatus])


    let userBalances = gameBetStatus?.userBalances || []
    let pot = gameBetStatus?.pot || 0
    let maxBet = gameBetStatus?.maxBet || 0
    let foldedPlayers = gameBetStatus?.foldedPlayers || []
    let leftPlayers = gameBetStatus?.leftPlayers || []
    let currentPlayer = gameBetStatus?.currentPlayer || 0
    let playerCards = gameBetStatus?.playerCards || []
    let timer = gameBetStatus?.timer || 30
    if (gameStatus === 'playing') {
        userBalances = currentMoveInfo?.userBalances || []
        maxBet = currentMoveInfo?.maxBet || 0
        pot = currentMoveInfo?.pot || 0
        userBalances = currentMoveInfo?.userBalances || []
        foldedPlayers = currentMoveInfo?.foldedPlayers || []
        leftPlayers = currentMoveInfo?.leftPlayers || []
        currentPlayer = currentMoveInfo?.currentPlayer || 0
        playerCards = currentMoveInfo?.playerCards || []
        timer = currentMoveInfo?.timer || 30
    }

    const handleToggleCard = (index) => {

        if (gameStatus !== 'playing') return;
        if (currentPlayer !== userData.id) return;

        if (selectedCards.includes(index)) {
            setSelectedCards(selectedCards.filter(cardIndex => cardIndex !== index))
        } else {
            if (selectedCards.length >= 4) return hotToast.error('You can only select 4 cards', { duration: 2000, position: 'bottom-center' })
            setSelectedCards([...selectedCards, index])
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('five_card_poker_table');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }
    const handleLeaveGame = async () => {
        try {
            const bodyData = { gameAddress: gameInfo?.gameAddress }
            const response = await axiosInstance.post('/api/game/5-card-poker/leave', bodyData);

            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                hotToast.success(responseData.message, { duration: 3000, position: 'bottom-center' })
                window.location.href = '/all-game-page'
            }

        } catch (error) {
            console.log(error)
            hotToast.error('Something went wrong', { duration: 3000, position: 'bottom-center' })
        }
    
    }

    const dealerUserId = gameInfo['dealerUserId']


    const AllPlayersMemo = useMemo(() => {
        return allNewPlayers.map((player, index) => {
            return (
                <div id={`stws_player_${player.userId}`} key={index} className={getClassName(index)} ref={el => usersRef.current[index] = el}>
                    <div className='stws-lo-player flex items-center flex-col shadow-2xl'>
                        <Player timer={timer} showDealer={dealerUserId === player.userId} foldedPlayers={foldedPlayers} leftPlayers={leftPlayers} userBalances={userBalances} playerCards={playerCards} selectedCards={selectedCards} handleToggleCard={handleToggleCard} currentMoveInfo={currentMoveInfo} gameBetStatus={gameBetStatus} pot={pot} maxBet={maxBet} gameStatus={gameStatus} totalPlayers={allNewPlayers.length} index={index} key={player.userId} player={player} currentPlayer={currentPlayer} />
                    </div>
                </div>
            );
        });
    }, [])


    return (
        <>
            <div id='five_card_poker_table' className='relative game  flex flex-col items-center h-full container'>
                <MenuActions isInGame={myProfile} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo}/>
                <div className=' h-[60%] p-2 bg-gray-200 border-2  mt-10 container rounded-full '>
                    <div className="relative p-2 h-full rounded-full border-2 bg-slate-900">
                        <div className="h-full rounded-full border-2" style={{
                            backgroundColor: '#222222'
                        }}>
                            <div id='stws_pot_money_box' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                <PotMoney pot={Number(pot).toFixed(2)} />
                            </div>
                            {allNewPlayers.map((player, index) => {
                                return (
                                    <div id={`stws_player_${player.userId}`} key={index} className={getClassName(index)} ref={el => usersRef.current[index] = el}>
                                        <div className='stws-lo-player flex items-center flex-col shadow-2xl'>
                                            <Player timer={timer} showDealer={dealerUserId === player.userId} foldedPlayers={foldedPlayers} leftPlayers={leftPlayers} userBalances={userBalances} playerCards={playerCards} selectedCards={selectedCards} handleToggleCard={handleToggleCard} currentMoveInfo={currentMoveInfo} gameBetStatus={gameBetStatus} pot={pot} maxBet={maxBet} gameStatus={gameStatus} totalPlayers={allNewPlayers.length} index={index} key={player.userId} player={player} currentPlayer={currentPlayer} />
                                        </div>
                                    </div>
                                );
                            })}
                            {}
                        </div>
                    </div>

                </div>
                {!isAllInWinning && (userData?.id === currentPlayer) && <div className='absolute bottom-10 h-auto flex w-full items-center justify-center'>
                    <FiveCardPokerActionButtons selectedCards={selectedCards} maxBet={maxBet} pot={pot} gameStatus={gameStatus} currentMoveInfo={currentMoveInfo} gameBetStatus={gameBetStatus} />
                </div>}
                {isAllInWinning && <AllInWinnerAnimation />}

            </div>

        </>
    )
}

export default PlayGame
